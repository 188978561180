import "./App.css";

import { Link, Route, Routes, useLocation } from "react-router-dom";
import HomePage from "./components/HomePage";
import WorkDetails from "./components/WorkDetails";
import { useEffect, useRef, useState } from "react";
import Info from "./components/Info";
import { Spiral as Hamburger } from "hamburger-react";

function App() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 720);
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
  const [showCompanyLogo, setShowCompanyLogo] = useState(true);
  const overlayRef = useRef(0);
  const location = useLocation();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      document.getElementById("toggle-text")?.classList?.remove("show-text");
      document.getElementById("toggle-text")?.classList?.add("hide-text");
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, []);

  // const ctaCondition = {
  //   width:
  //     location.pathname === "/work-details" || location.pathname === "/about-us"
  //       ? "100%"
  //       : "auto",
  // };

  const showHideHamburgerSlideDownMenu = () => {
    if (overlayRef.current) {
      if (showHamburgerMenu) {
        setShowCompanyLogo(true);
        overlayRef.current.style.height = "0%";
        setShowHamburgerMenu(false);
      } else {
        setShowCompanyLogo(false);
        overlayRef.current.style.height = "100%";
        setShowHamburgerMenu(true);
      }
    }
  };

  const slideDownDiv = () => {
    return (
      <div className="overlay" ref={overlayRef}>
        <div className="header-slide-menu-main-div">
          <div className="left">
            <div className="title">de Monk</div>
            <div
              className="contact-details"
              style={{ fontSize: "16px", fontWeight: "600" }}
            >
              Contact Details <br />
              <div
                style={{
                  fontSize: "16px",
                  lineHeight: "20px",
                  fontWeight: "normal",
                }}
              >
                contact@demonkdesign.com <br />
                +91 1234567890
              </div>
            </div>
            <div
              className="address"
              style={{ fontSize: "16px", fontWeight: "600" }}
            >
              Address
              <div
                style={{
                  marginTop: "24px",
                  lineHeight: "21px",
                  fontWeight: "normal",
                }}
              >
                U-11, C-49, Runwal Platinum, <br />
                Ramnagar Colony, Bavdhan, Pune
              </div>
            </div>
          </div>
          <div className="right">
            <ul>
              <li>
                <Link to="/" onClick={() => showHideHamburgerSlideDownMenu()}>
                  Home
                </Link>
              </li>

              <li>
                <Link
                  onClick={() => {
                    showHideHamburgerSlideDownMenu();

                    const targetDiv =
                      document.querySelector(".projectsSection");
                    if (targetDiv) {
                      targetDiv.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                >
                  Work
                </Link>
              </li>
              <li>
                <Link
                  to="/about-us"
                  onClick={() => showHideHamburgerSlideDownMenu()}
                >
                  Info
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="container" style={{ width: "100%" }}>
        {/* ********************* Header Navigation section ********************* */}
        {location.pathname !== "/work-details" && (
          <div className="static-header">
            {slideDownDiv()}
            <header className="header">
              <Link to="/">
                <div className="logo">
                  <div
                    className="logoText"
                    style={{ display: showCompanyLogo ? "block" : "none" }}
                  >
                    <span>de</span>
                    <span id={"toggle-text"} className={"show-text"}>
                      sign
                    </span>
                    <span> Monk</span>
                  </div>
                </div>
              </Link>

              <div className="logoImage">
                <Hamburger
                  direction="left"
                  toggled={showHamburgerMenu}
                  toggle={() => {
                    setShowHamburgerMenu();
                    showHideHamburgerSlideDownMenu();
                  }}
                  size={30}
                />
              </div>
            </header>

            <div
              className="static-backouground-image"
              style={{
                display:
                  (location.pathname === "/about-us" ||
                    location.pathname === "/work-details") &&
                  "none",
              }}
            >
              {isDesktop ? (
                <img src="/images/home-title-image-desktop.png" alt="" />
              ) : (
                <img src="/images/home-title-image-mobile.png" alt="" />
              )}
            </div>
          </div>
        )}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/work-details" element={<WorkDetails />} />
          <Route path="/about-us" element={<Info />} />
        </Routes>
      </div>

      {/* ********************* Footer Section ********************* */}
      {isDesktop ? (
        <div className={"ctaSection"}>
          <div className={"ctaContent"}>
            <div className="left-section" /* style={ctaCondition} */>
              <p className={"ctaText"}>
                Team up with us to turn your big ideas into designs that wow and
                win-
                <br />
                <span>because great visions deserve great execution!</span>
              </p>
              <button className={"ctaButton"}>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/87db8753554d17e5bd92ab65906b8965df8d9559617211e3e2a074593d16ba56?apiKey=606ec01972c540f8a7cba72c100cfc9e&"
                  alt=""
                  className={"ctaButtonIcon"}
                />
                Let's connect
              </button>
            </div>
            {(location.pathname !== "/work-details" ||
              location.pathname !== "/about-us" ||
              !isDesktop ||
              (location.pathname !== "/about-us" && !isDesktop)) && (
              <>
                <div className={"ctaDivider"}></div>
                <div className="right-section">
                  <p className={"socialMediaText"}>Join the adventure!</p>
                  <div className={"socialMediaIcons"}>
                    <img
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/a8126d3e2e52801433ef9794fc84b13269bed4f2a7f04ed9ee4950ff4a34b5a6?apiKey=606ec01972c540f8a7cba72c100cfc9e&"
                      alt="Social Media Icon"
                      className={"socialIcon"}
                    />
                    <img
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/a8126d3e2e52801433ef9794fc84b13269bed4f2a7f04ed9ee4950ff4a34b5a6?apiKey=606ec01972c540f8a7cba72c100cfc9e&"
                      alt="Social Media Icon"
                      className={"socialIcon"}
                    />
                    <img
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/fb45d1ea401add40235a2dba39c4ee0a0e7b329b7bbeac1fa63c48a1524051c7?apiKey=606ec01972c540f8a7cba72c100cfc9e&"
                      alt="Social Media Icon"
                      className={"socialIcon"}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className={"ctaSection"}>
          <div className={"ctaContent"}>
            <div className="left-section">
              <p className={"ctaText"}>
                Team up with us to turn your big ideas into designs that wow and
                win-
                <br />
                <span>because great visions deserve great execution!</span>
              </p>
              <hr style={{ width: "130px", height: "1px" }} />
              <button className={"ctaButton"}>
                {location.pathname !== "/about-us" && (
                  <img
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/87db8753554d17e5bd92ab65906b8965df8d9559617211e3e2a074593d16ba56?apiKey=606ec01972c540f8a7cba72c100cfc9e&"
                    alt=""
                    className={"ctaButtonIcon"}
                  />
                )}
                Let's Connect
              </button>
            </div>
            <div className="right-section">
              <div className={"socialMediaIcons"}>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/a8126d3e2e52801433ef9794fc84b13269bed4f2a7f04ed9ee4950ff4a34b5a6?apiKey=606ec01972c540f8a7cba72c100cfc9e&"
                  alt="Social Media Icon"
                  className={"socialIcon"}
                />
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/fb45d1ea401add40235a2dba39c4ee0a0e7b329b7bbeac1fa63c48a1524051c7?apiKey=606ec01972c540f8a7cba72c100cfc9e&"
                  alt="Social Media Icon"
                  className={"socialIcon"}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
