import React from "react";
import ImageGallery from "./ImageGallery";

const BalancedDetails = ({ data }) => {
  const {
    banner_image,
    section_1_title,
    section_1_description,
    section_1_images,
    section_2_title,
    section_2_description,
    section_2_images,
    section_3_title,
    section_3_images,
    section_4_title,
    section_4_description,
  } = data;

  return (
    <>
      <img
        loading="lazy"
        src={banner_image}
        alt="Hero"
        className={"heroImage"}
      />
      <main className={"product-details-container"}>
        <h1 className={"sectionTitle"}>{section_1_title}</h1>
        <p className={"sectionDescription"}>{section_1_description}</p>
        <ImageGallery
          images={section_1_images}
          imagePattern={section_1_images.length === 1 ? 3 : 1}
        />
        <h2 className={"sectionTitle"}>{section_2_title}</h2>
        <p className={"sectionDescription"}>{section_2_description}</p>
        <ImageGallery
          images={section_2_images}
          imagePattern={section_2_images.length === 2 && 1}
        />

        <blockquote className={"quote"}>{section_3_title}</blockquote>

        {/* pattern  - multiple images in a stack */}
        <ImageGallery images={section_3_images} imagePattern={3} />

        <section className={"finalStatement"}>
          <h2 className={"finalStatementTitle"}>{section_4_title}</h2>
          <p className={"finalStatementDescription"}>{section_4_description}</p>
        </section>
      </main>
    </>
  );
};

export default BalancedDetails;
