import { useEffect } from "react";
import TextContainer from "./info/TextContainer";
import ImageContainer from "./info/ImageContainer";

function Info() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="page-content" style={{ marginTop: "150px" }}>
        <TextContainer textStyle={1} />
      </div>
      <ImageContainer />
      <div className="page-content">
        <TextContainer textStyle={2} />
        <TextContainer textStyle={2} />
        <TextContainer textStyle={2} />
      </div>
    </>
  );
}

export default Info;
