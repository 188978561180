import React, { useEffect, useRef, useState } from "react";
import ProjectCard from "./ProjectCard";
import HomePageData from "../data/HomePage";
import HomePageAllWorkDesktop from "../data/HomePageAllWorkDesktop";
import HomePageAllWorkMobile from "../data/HomePageAllWorkMobile";
import { debounce } from "lodash";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/swiper-bundle.css"; // Import Swiper styles
import 'swiper/css/effect-creative';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { EffectCreative, Navigation, Pagination } from "swiper/modules";


function HomePage() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 720);
  const [height, setHeight] = useState(window.innerHeight);
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
  const [showCompanyLogo, setShowCompanyLogo] = useState(true);

  const [lastScrollY, setLastScrollY] = useState(window.scrollY);
  const [count, setCount] = useState(0);
  const overlayRef = useRef(0);

  // Mobile Hero section
  const touchStartRef = useRef(null);
  const swiperRefMobile = useRef(null);
  const swiperRefDesktop = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const throttlingFunction = (func, delay) => {
    let timeout = null;

    return (...args) => {
      if (timeout) {
        return;
      }

      func(...args);
      timeout = setTimeout(() => {
        timeout = null;
      }, delay);
    };
  };

  useEffect(() => {
    if (isDesktop) {
      const handleScroll = debounce(() => {
        const currentScrollY = window.scrollY;
        const swiperInstanceOne = swiperRefDesktop.current.swiper;

        if (currentScrollY > 500 && currentScrollY < 1200) {
          if (currentScrollY > lastScrollY) {
            swiperInstanceOne?.slideNext();
          } else if (currentScrollY > 1300) {
            swiperRefDesktop.current.scrollTo(1);
          } else {
            swiperInstanceOne?.slidePrev();
          }

          setLastScrollY(currentScrollY);
        }
      }, 100);

      // window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      const handleTouchStart = (event) => {
        touchStartRef.current = event.touches[0].clientY;
      };

      const handleTouchMove = (event) => {
        if (touchStartRef.current === null) {
          return;
        }

        const touchEnd = event.touches[0].clientY;
        const deltaY = touchStartRef.current - touchEnd;
        const swiperInstanceOne = swiperRefMobile.current.swiper;

        if (deltaY > 0) {
          swiperInstanceOne?.slideNext();
        } else if (deltaY < 0) {
          swiperInstanceOne?.slidePrev();
        }

        touchStartRef.current = touchEnd;
      };

      const handleTouchEnd = () => {
        touchStartRef.current = null;
      };

      // window.addEventListener("touchstart", handleTouchStart);
      // window.addEventListener(
      //   "touchmove",
      //   throttlingFunction(handleTouchMove, 500)
      // );
      // window.addEventListener("touchend", handleTouchEnd);

      return () => {
        window.removeEventListener("touchstart", handleTouchStart);
        window.removeEventListener("touchmove", handleTouchMove);
        window.removeEventListener("touchend", handleTouchEnd);
      };
    }
  }, [count, lastScrollY]);

  const showHideHamburgerSlideDownMenu = () => {
    if (overlayRef.current) {
      if (showHamburgerMenu) {
        setShowCompanyLogo(true);
        overlayRef.current.style.height = "0%";
        setShowHamburgerMenu(false);
      } else {
        setShowCompanyLogo(false);
        overlayRef.current.style.height = "100%";
        setShowHamburgerMenu(true);
      }
    }
  };

  useEffect(() => {
    const handleSize = () => {
      setIsDesktop(window.innerWidth > 720);
      setHeight((prevHeight) => {
        if (prevHeight !== height) {
          setIsDesktop(window.innerWidth > 720);
          return window.innerHeight;
        }
      });
    };
    window.addEventListener("resize", handleSize);
    return () => window.removeEventListener("reset", handleSize);
  }, [isDesktop, height]);

  return (
    <>
      <div className="container">
        {/* ********************* Hero Section - slider ********************* */}
        {isDesktop ? (
          <>
            <section className="heroSection">
              <Swiper
                // ref={swiperRefDesktop}
                spaceBetween={50}
                slidesPerView={1}
                pagination={{
                  type: 'fraction',
                }}
                rewind={true}
                navigation={true}
                grabCursor={true}
                effect={'creative'}
                creativeEffect={{
                  prev: {
                    shadow: true,
                    translate: ['-20%', 0, -1],
                  },
                  next: {
                    translate: ['100%', 0, 0],
                  },
                }}
                modules={[EffectCreative, Navigation, Pagination]}
                className="heroSectionSwiper"

              >
                {HomePageData.map((data) => (
                  <SwiperSlide key={data.id}>
                    <div className="desktop-hero-card">
                      <div className="left">
                        <div className="desktop-hero-title">{data.title}</div>
                        <div className="desktop-hero-description">
                          {data.description}
                        </div>
                      </div>
                      <div className="desktop-hero-image">
                        <img src={data.image} alt={data.title} />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </section>
          </>
        ) : (
          <section className="mobile-hero-section">
            <Swiper 
              /* ref={swiperRefMobile} */ 
              spaceBetween={20} 
              slidesPerView={1}
              // direction={"horizontal"}
              // mousewheel={true}
              // touchReleaseOnEdges={true}
              // pagination={{
              //   clickable: true,
              // }}
              // modules={[Mousewheel]}
                pagination={{
                  type: 'fraction',
                }}
                rewind={true}
                navigation={true}
                grabCursor={true}
                effect={'creative'}
                creativeEffect={{
                  prev: {
                    shadow: true,
                    translate: ['-20%', 0, -1],
                  },
                  next: {
                    translate: ['100%', 0, 0],
                  },
                }}
                modules={[EffectCreative, Navigation, Pagination]}
                className="heroSectionSwiper"
            >
              {HomePageData.map((data) => (
                <SwiperSlide key={data.id}>
                  <div className="mobile-hero-card">
                    <div className="mobile-hero-title">{data.title}</div>
                    <div className="mobile-hero-description">
                      {data.description}
                    </div>
                    <div className="mobile-hero-image">
                      <img src={data.image} alt={data.title} />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </section>
        )}

        {/* ********************* Project Section ********************* */}
        {isDesktop ? (
          <section className={"projectsSection"}>
            <div className={"projectsGrid"}>
              {HomePageAllWorkDesktop[0].map((project, index) => (
                <ProjectCard key={index} {...project} />
              ))}
            </div>

            <div className="projectsGrid">
              {HomePageAllWorkDesktop[1].map((project, index) => (
                <ProjectCard key={index} {...project} />
              ))}
            </div>

            <div className="projectsGrid">
              {HomePageAllWorkDesktop[2].map((project, index) => (
                <ProjectCard key={index} {...project} />
              ))}
            </div>
          </section>
        ) : (
          <section className={"projectsSection"}>
            <div className={"projectsGrid"}>
              {HomePageAllWorkMobile[0].map((project, index) => (
                <ProjectCard key={index} {...project} />
              ))}
            </div>

            <div className="projectsGrid">
              {HomePageAllWorkMobile[1].map((project, index) => (
                <ProjectCard key={index} {...project} />
              ))}
            </div>
          </section>
        )}
      </div>
    </>
  );
}

export default HomePage;
