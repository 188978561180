import React from "react";

function TextContainer({ textStyle = 1 }) {
  return (
    <div className="two-column-about-section">
      <span className="left">Lorem Ipsum is simply dummy text</span>
      {textStyle === 1 ? (
        <span className="right">
          <div className="row">
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here, content
            here', making it look like readable English.
          </div>
          <div className="row">
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here, content
            here', making it look like readable English.
          </div>
        </span>
      ) : (
        <span className="right">
          <div className="row">
            <div className="row-1">Lorem ipsum</div>
            <div className="row-2">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.
            </div>
          </div>
          <div className="row">
            <div className="row-1">Lorem ipsum</div>
            <div className="row-2">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.
            </div>
          </div>
        </span>
      )}
    </div>
  );
}

export default TextContainer;
