import React from "react";
import { Link } from "react-router-dom";

function ProjectCard({ image, title, tags, height, data }) {
  const tagsValue = tags.map((tag, index) => {
    if (index < tags.length) {
      if (index === tags.length - 1) {
        return tag;
      } else {
        return tag.concat(" | ");
      }
    }
  });

  const saveData = (data) => {
    sessionStorage.setItem("work-data", JSON.stringify(data));
  };

  return (
    <>
      <article className="project-article">
        <Link
          to="/work-details"
          replace={false}
          onClick={() => {
            saveData(data);
          }}
          className={Object.keys(data).length === 0 ? "disabled-link" : ""}
        >
          <center>
            <span className="project-article-image">
              <img
                src={image}
                alt={title}
                style={{ height: height }}
                className={"projectImage"}
              />
            </span>
          </center>
          <h3 className={"projectTitle"}>{title}</h3>
          <p className={"projectDescription"}>{tagsValue}</p>
        </Link>
      </article>
    </>
  );
}

export default ProjectCard;
