import React from "react";

const ImageGallery = ({ images, imagePattern }) => {
  return (
    <div className={"imageGallery"}>
      <div className={"imageRow"}>
        {/* pattern  - TWO images in a ROW */}
        {imagePattern === 1 &&
          images.map((image, index) => (
            <div key={index} className={"imageColumn"}>
              <div className={"imageWrapper"}>
                <img
                  loading="lazy"
                  src={image}
                  alt=""
                  className={"galleryImage"}
                />
              </div>
            </div>
          ))}

        {/* pattern  - THREE images in a TWO rows | 1st Row -> landscape image , 2nd Row -> TWO images in a Row */}
        {imagePattern === 2 && (
          <>
            <div className="main-section">
              <div className="one-row">
                <img src={images[0]} alt="" />
              </div>
              <div className="second-row">
                <img src={images[1]} alt="" />
                <img src={images[2]} alt="" />
              </div>
            </div>
          </>
        )}

        {/* pattern - Multiple images in a Stack */}
        {imagePattern === 3 && (
          <>
            <div className="images-stack-section">
              {images.map((image) => (
                <img src={image} alt="" />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ImageGallery;
