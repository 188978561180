import React, { useEffect } from "react";
import BalancedDetails from "./work-details/BalencedDetails";
import "./work-details/WorkStyle.css";

function WorkDetails() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const dataRetrieved = JSON.parse(sessionStorage.getItem("work-data"));

  return (
    dataRetrieved && (
      <div style={{ width: "100%", overflowX: "hidden" }}>
        <BalancedDetails data={dataRetrieved} />
      </div>
    )
  );
}

export default WorkDetails;
