import React from "react";

function ImageContainer() {
  return (
    <div className="image-about-container">
      <img
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/c677cd76c159573df428aeca0191a0aeaac0613d50375593b11a351056d67216?apiKey=606ec01972c540f8a7cba72c100cfc9e&"
        alt=""
      />
    </div>
  );
}

export default ImageContainer;
